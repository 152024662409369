import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type FaqProps = {
    question: string,
    answer: string,
    isOpen: boolean,
    toggle: (data: any) => void
} 

const Faq = () => {
  const { t } = useTranslation();

  const FaqItem = ({ question, answer, isOpen, toggle }: FaqProps) => (
    <div className="mb-2">
      <button
        className="w-full flex items-center justify-between p-2 bg-gray-100 hover:bg-gray-300 focus:outline-none rounded-lg"
        onClick={toggle}
      >
        <div className="font-semibold text-green-900">{t(question)}</div>
        <div>{isOpen ? '-' : '+'}</div>
      </button>
      {isOpen && (
        <div className="p-[1rem] bg-white text-left">
          <p>{t(answer)}</p>
        </div>
      )}
    </div>
  );
  
  const [faqs, setFaqs] = useState([
    {
      question: "question_1",
      answer: "resp_1",
      isOpen: false,
    },
    {
      question: "question_2",
      answer: "resp_2",
      isOpen: false,
    },
    {
      question: "question_3",
      answer: "resp_3",
      isOpen: false,
    },
  ]);

  const toggleFaq = (index: number) => {
    const newFaqs = [...faqs];
    newFaqs[index].isOpen = !faqs[index].isOpen;
    setFaqs(newFaqs);
  };

  return (
    <div className="w-[100%] p-4 mt-[5rem]">
      <h1 className="text-green-900 font-bold text-5xl mb-[3rem]">{t('faq')}</h1>
      {faqs.map((faq, index) => (
        <FaqItem
          key={index}
          question={faq.question}
          answer={faq.answer}
          isOpen={faq.isOpen}
          toggle={() => toggleFaq(index)}
        />
      ))}
    </div>
  );
};

export default Faq;