const YouTubeVideo = () => {
  return (
    <div className="w-[25rem] sm:w-[60%] mx-auto mt-[5.5rem] sm:mt-0">
      <div className="relative" style={{ paddingBottom: '56.25%' }}>
        <iframe
          className="absolute inset-0 w-full h-full"
          src={`https://www.youtube.com/embed/yCP4BUtauco?si=qIYGhBmS2X8Vj1qC`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default YouTubeVideo;