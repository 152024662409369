import { useTranslation } from "react-i18next";

function ModulesCards() {
    const { t } = useTranslation();
    
    return (
        <div className="mt-[2rem] flex flex-row flex-wrap sm:justify-between justify-center">
            <div className="w-[20rem] sm:h-[12rem] h-[7rem] bg-green-900 rounded-2xl m-4 flex flex-col justify-center items-center">
                <h1 className="font-extrabold sm:text-3xl text-3xl text-center text-gray-200">{t('advantages')}</h1>
            </div>
            <div className="w-[20rem] h-[12rem] bg-gray-200 rounded-2xl m-4 flex flex-col justify-start items-center">
                <h1 className="font-bold text-2xl text-green-900 mb-[1rem] mt-[1.5rem]">{t('social')}</h1>
                <p className="ml-[0.5rem] mr-[0.5rem] font-normal text-xl text-center text-green-900">{t('satisfied')}</p>
            </div>
            <div className="w-[20rem] h-[12rem] bg-gray-200 rounded-2xl m-4 flex flex-col justify-start items-center">
                <h1 className="font-bold text-2xl text-green-900 mb-[1rem] mt-[1.5rem]">{t('environmentalImpact')}</h1>
                <p className="ml-[0.5rem] mr-[0.5rem] font-normal text-xl text-center text-green-900">{t('pollutantEmissions')}</p>
            </div>
            <div className="w-[20rem] h-[12rem] bg-gray-200 rounded-2xl m-4 flex flex-col justify-start items-center">
                <h1 className="font-bold text-2xl text-green-900 mb-[1rem] mt-[1.5rem]">{t('health')}</h1>
                <p className="ml-[0.5rem] mr-[0.5rem] font-normal text-xl text-center text-green-900">{t('ANVISA')}</p>
            </div>
            <div className="w-[20rem] h-[12rem] bg-gray-200 rounded-2xl m-4 flex flex-col justify-start items-center">
                <h1 className="font-bold text-2xl text-green-900 mb-[1rem] mt-[1.5rem]">{t('safety')}</h1>
                <p className="ml-[0.5rem] mr-[0.5rem] font-normal text-xl text-center text-green-900">{t('nbr')}</p>
            </div>
            <div className="w-[20rem] h-[12rem] bg-gray-200 rounded-2xl m-4 flex flex-col justify-start items-center">
                <h1 className="font-bold text-2xl text-green-900 mb-[1rem] mt-[1.5rem]">{t('economical')}</h1>
                <p className="ml-[0.5rem] mr-[0.5rem] font-normal text-xl text-center text-green-900">{t('lowAcquisition')}</p>
            </div>
        </div>
    );
}

export default ModulesCards;