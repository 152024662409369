import './App.css';
import logo from './assets/logo_png.png';
import background from './assets/background.jpg';
import ModulesCards from './ModulesCards';
import leo from './assets/leo.jpg'
import Faq from './Faq';

import bus_01 from './assets/cases/01.jpg'; 
import bus_09 from './assets/amigosdobem.jpg'; 
import bus_03 from './assets/campinas.jpg'; 
import bus_04 from './assets/cases/04.jpeg'; 
import bus_05 from './assets/saopaulo.jpg';
import bus_06 from './assets/20240121_153910.jpg';
import bus_07 from './assets/20240115_181719.jpg';
import bus_08 from './assets/34534534535468788.jpeg';

import YouTubeVideo from './YoutubeComponent';
import InternationalizationFlags from './components/InternationalizationFlags';
import { useTranslation } from 'react-i18next';

const ibagens = [
  {id: 9, image: bus_09, text: 'Primeiros ônibus direto da Caio / ONG Amigos do Bem', subText: 'versionCaio'},
  {id: 8, image: bus_08, text: 'Grande Bauru / Bauru - SP', subText: 'latestVersion24'},
  {id: 7, image: bus_07, text: 'Avipan / Panambi - RS', subText: 'forColdRegions'},
  {id: 6, image: bus_06, text: 'TURP / Petrópolis - RJ', subText: 'latestVersion'},
  {id: 4, image: bus_05, text: 'Transppass / São Paulo - SP', subText: 'thirdVersion'},
  {id: 3, image: bus_01, text: 'Viação Osasco / Osasco - SP', subText: 'thirdVersion'},
  {id: 2, image: bus_03, text: 'Itajaí Transportes / Campinas - SP', subText: 'secondVersion'},
  {id: 0, image: bus_04, text: 'Integra Salvador / Salvador - BA', subText: 'firstVersion'},
]

function App() {
  const { t } = useTranslation();

  return (
    <div className="App absolute">
      <header className='w-[100%] h-[3rem] bg-white/50 flex flex-row justify-around items-center'>
        <div className='flex flex-row items-end'>
          <img src={logo} alt="Areja Logo" className='w-[10rem] '/> 
          <p className='text-green-900 font-medium text-left sm:block hidden'>{t('headerIntro')}.</p>
        </div>
        <div className='w-[10%] flex flex-row justify-end'>
          <InternationalizationFlags />
        </div>
      </header>
      <div className='absolute w-[100%] h-[50rem] -z-10 bg-cover bg-center filter brightness-125 blur-[6px]' style={{backgroundImage: `url(${background})`}}>
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div className='flex flex-col items-center mt-[6rem] ml-[2.5%] sm:ml-[20%] sm:mr-[20%] sm:mt-[15rem] mr-[2.5%] sm:flex-row sm:justify-between sm:items-center'>
        <div className='w-[100%] sm:w-[40%]'>
          <h1 className='sm:text-[5rem] text-[5.5rem] sm:text-left text-white font-bold'>Arejabus</h1>
          <p className='mt-[2rem] text-lg font-semibold text-white sm:text-left'>{t('description')}</p>
          <a href="https://api.whatsapp.com/send?phone=557192327675" target="_blank">
            <button className='hover:bg-blue-600 sm:float-left mt-[2rem] w-[15rem] h-[3rem] rounded-md bg-green-700 text-white text-2xl font-semibold'>{t('contact')}</button>
          </a>
        </div>
        <YouTubeVideo />
      </div>
      <main className='mt-[9rem] sm:mt-[17rem] ml-[2.5%] sm:ml-[20%] sm:mr-[20%] mr-[2.5%]'>
        <ModulesCards />
        <div className='mt-[5rem] sm:mt-[5rem] w-[100%] flex flex-col items-center sm:flex-row sm:justify-between'>
          <img src={leo} alt="Leo" className='rounded-full w-[80%] h-[80%] sm:w-[35%] sm:h-[35%]'/>
          <div className='w-[100%] mt-10 sm:w-[60%] flex flex-col justify-center'>
            <h1 className='sm:text-5xl text-5xl sm:text-left text-green-900 font-bold'>{t('passenger2Passenger')}</h1>
            <p className='mt-[2rem] text-lg font-semibold text-gray-400 sm:text-left'>“{t('leoPhrase')}</p>
          </div>
        </div>
        <div className='mt-[7rem] sm:mt-[8rem]'>
          <h1 className='text-green-900 font-bold text-5xl mb-[1rem]'>{t("successStories")}</h1>
          <div className='flex flex-row flex-wrap sm:justify-around justify-center'>
            {ibagens.map((data) => 
            data.id === 9 ?
            (
              <a href="https://caio.com.br/noticias/ong+93amigos+do+bem94+adquire+seis+d4nibus+foz+super+2500+caio2c+com+novo+sistema+de+climatizac7c3o+natural+embarcado-605.html">
                <div  key={data.id} className='w-[30rem] h-[30rem] bg-slate-200 rounded-2xl my-10 bg-cover bg-center'
                  style={{ backgroundImage: `url(${data.image})`}}>
                  <div className='w-[30rem] h-[30rem] bg-gradient-to-t from-white via-white/5  to-white/0 flex flex-col justify-end'>
                    <h1 className='text-green-900 font-bold text-xl mb-[0.5rem]'>{data.text}</h1>
                    <p className='text-green-900 font-normal text-base mb-[0.5rem]'>{t(data.subText)}</p>
                  </div>
                </div>
              </a>
            ) :
            (
              <div  key={data.id} className='w-[30rem] h-[30rem] bg-slate-200 rounded-2xl my-10 bg-cover bg-center'
                style={{ backgroundImage: `url(${data.image})`}}>
                <div className='w-[30rem] h-[30rem] bg-gradient-to-t from-white via-white/5  to-white/0 flex flex-col justify-end'>
                  <h1 className='text-green-900 font-bold text-xl mb-[0.5rem]'>{data.text}</h1>
                  <p className='text-green-900 font-normal text-base mb-[0.5rem]'>{t(data.subText)}</p>
                </div>
              </div>
            )
            )}
          </div>
        </div>
        <Faq />
      </main>
      <footer className='w-[100%] h-[8rem] bg-gray-200 mt-[5rem]'>
        <div className='sm:ml-[20%] ml-[2.5%] h-[8rem] sm:mr-[20%] mr-[2.5%] flex flex-row justify-between items-center'>
          <img src={logo} alt="Areja Logo" className='w-[10rem]'/>
          <a href="https://api.whatsapp.com/send?phone=557192327675" target="_blank">
              <button className='hover:bg-blue-600 w-[12rem] h-[3rem] rounded-md bg-green-700 text-white text-2xl font-semibold'>{t("cont")}</button>
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
