/* eslint-disable no-unreachable */
import { useTranslation } from 'react-i18next';
import brazil_flag from '../assets/flags/brazil.png';
import esp_flag from '../assets/flags/esp.png';
import en_flag from '../assets/flags/united-kingdom.png'; 

export default function InternationalizationFlags() {
    const { t, i18n } = useTranslation();
    
    const onFlagClick = (event:any, id:any) => {
        switch (id) {
            case 0: i18n.changeLanguage("pt"); break;
            case 1: i18n.changeLanguage("es"); break;
            case 2: i18n.changeLanguage("en"); break;

            default: i18n.changeLanguage("pt");
                break;
        }
    }

    return (
        <>
            {[brazil_flag, esp_flag, en_flag]
                .map((flag, id) => 
                    <img
                      key={id} // Adiciona uma key única para cada elemento
                      alt=""
                      src={flag}
                      className="inline-block h-8 w-8 rounded-full"
                      onClick={(event) => onFlagClick(event, id)} // Passa o id como segundo argumento
                    />
                )
            }
        </>
    )
}
